<template>

  <div>
    <div class="center">

      <!-- Top Controls -->
      <div class="control-container" :style="`opacity: ${ transitioningToFromMenu ? 0 : 0.6 }`">
        <div @click="goToMenu()" style="position: absolute; left: 0%;">
          <img style="max-width: 3vw; vertical-align: middle;" src="../assets/chevron.png" alt="return-chevron">
          <i style="margin-left: 5px;">return</i>
        </div>

        <div @click="enterPhotoView()" style="position: absolute; right: 0%;" v-show="sculpture.addedImgs">
          <i style="margin-right: 5px">more</i>
          <img style="max-width: 3vw; transform: rotate(180deg); vertical-align: middle;" src="../assets/chevron.png" alt="return-chevron">
        </div>
      </div>

      <!-- Main Content Card -->
      <img id="head-img" class="sculpture-img" :src="sculpture.imgSrc" alt="sculpture">
      <div class="content-container">
        <h1 v-show="sculpture.title">{{ sculpture.title }} <span style="font-size: 9pt; font-weight: normal">{{ sculpture.year }}</span></h1>
        <p v-show="sculpture.location">{{ sculpture.location }}</p>
        <p v-show="sculpture.medium">{{ sculpture.medium }}</p>
        <p v-show="sculpture.dimensions">{{ sculpture.dimensions }}</p>
        <p v-show="sculpture.weight">{{ sculpture.weight }}</p>
        <p v-show="sculpture.explanation">{{ sculpture.explanation }}</p>
        <a :href="sculpture.link" target="_blank">
          <p class="last" v-show="sculpture.linkName">{{ sculpture.linkName }}</p>
        </a>
      </div>

    </div>

  <a class="email" :href="`mailto:office@julianvossandreae.com?subject=Web Inquiry (${sculpture.title})`">
    <footer v-if="!hideMailButton" :style="`opacity: ${ transitioningToFromMenu ? 0 : 1 }; transition: 500ms;`" class="center">
      <p style="color: white; font-size: 14pt;">Email Inquiry</p>
    </footer>
  </a>

  <!-- Slideshow View -->
  <div class="center">
    <transition :name="`photo-${ swipeDirection ? 'in' : 'out' }`">
      <div v-if="photoView" style="position: fixed; top: 4.75%; z-index: 8;">
        <div v-for="img in sculpture.addedImgs" :key="img.id">
          <transition :name="`photo-${ swipeDirection ? 'in' : 'out' }`">
            <div style="position: fixed;" v-show="imgNum === sculpture.addedImgs.indexOf(img) && photoView">
              <img class="slideshow-imgs" :src="img" alt="There Seems To Be An Issue Loading This Image.">
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <div v-if="prepareMatte" class="pv-background-matte" :style="`opacity: ${ photoView ? 1 : 0 };`"></div>

    <img @click="handleRightSwipe()" v-if="sculpture.addedImgs && photoView" class="slideshow-chevrons" :style="`left: 5%; opacity: ${ hideChevrons ? 0 : 0.6 }; ${ applyChevronTransition ? 'transition: 500ms;' : '' }`" src="../assets/chevron.png" alt="left-chevron">
    <div v-if="sculpture.addedImgs && photoView">
      <img @click="handleLeftSwipe()" class="slideshow-chevrons" :style="`right: 5%; transform: rotate(180deg); opacity: ${ hideChevrons ? 0 : 0.6 }; ${ applyChevronTransition ? 'transition: 500ms;' : '' }`" src="../assets/chevron.png" alt="right-chevron">
    </div>
  </div>

  </div>
</template>

<script>

export default {
  props: [
    "sculpture"
  ],
  data: () => {
    return {
      /* captures position of the x coordinate of when a touch gesture begins to detect a swipe */
      touchStartX: 0,
      /* true when slideshow is activated to show additional image material */
      photoView: false,
      /* 0 based indexing for all additional photos */
      imgNum: 0,
      /* controls whether the animation that pushes images in the slideshow starts from the left or right side */
      swipeDirection: true,
      /* hides the two navigation chevrons at the top during the transition for a smoother animation */
      hideChevrons: false,
      /* controls whether a 500ms fade in occurs on the top chevrons, disabled when image transition starts, enabled when it ends */
      applyChevronTransition: true,
      /* true when the overall state of the view is in an animated transition */
      inTransit: false,
      prepareMatte: false,
      viewportDOMElement: undefined,
      endPinch: false,
      transitioningToFromMenu: true,
      hideMailButton: false
    }
  },
  mounted() {

    setTimeout(() => {
      this.transitioningToFromMenu = false;
    }, 500);

    this.viewportDOMElement = document.getElementById("viewport");

    location.href = "#head-img";
    document.addEventListener('touchstart', this.touchHandler);
    document.addEventListener('touchend', this.touchHandler);
    document.addEventListener('gestureend', this.endPinchHandler);
  },
  unmounted() {
    document.removeEventListener('touchstart', this.touchHandler);
    document.removeEventListener('touchend', this.touchHandler);
    document.removeEventListener('gestureend', this.endPinchHandler);
  },
  methods: {
    touchHandler(ev) {

      if (ev.type === 'touchstart') this.touchStartX = ev.changedTouches[0].screenX;

      // go to main menu if not in photo view
      else if ((this.touchStartX + 30) < ev.changedTouches[0].screenX && !this.photoView && !this.inTransit) {
        this.goToMenu();
      }

      // go to next photo in photoView
      else if ((this.touchStartX - 30) > ev.changedTouches[0].screenX && this.photoView && !this.inTransit) {
        this.handleLeftSwipe();
      }

      // enter photoView
      else if ((this.touchStartX - 30) > ev.changedTouches[0].screenX && this.sculpture.addedImgs && !this.inTransit) {
        this.enterPhotoView();
      }

      // go to previous photo in photoView
      else if ((this.touchStartX + 30) < ev.changedTouches[0].screenX && this.sculpture.addedImgs && !this.inTransit) {
        this.handleRightSwipe();
      }
    },
    handleLeftSwipe() {

      setTimeout(() => {
        if (this.endPinch) return;
        this.transitionRoutine(true);
        (this.imgNum + 1) === this.sculpture.addedImgs.length ? this.photoView = false : this.imgNum++;
      }, 50);
    },
    handleRightSwipe() {
      
      setTimeout(() => {
        if (this.endPinch) return;
        this.transitionRoutine(false);
        this.imgNum === 0 ? this.photoView = false : this.imgNum--;
      }, 50);
    },
    enterPhotoView() {

      this.viewportDOMElement.setAttribute("content", "width=device-width,initial-scale=1.0");
      this.prepareMatte = true;

      setTimeout(() => {
        this.transitionRoutine(true);
        this.imgNum = 0;
        this.photoView = true;
      }, 50)
    },
    endPinchHandler() {

      this.endPinch = true;
      setTimeout(() => {
        this.endPinch = false;
      }, 150);
    },
    transitionRoutine(swipeDirection) {

      this.applyChevronTransition = false;
      this.hideChevrons = true;
      this.swipeDirection = swipeDirection;
      
      this.inTransit = true;
      setTimeout(() => {
        this.inTransit = false;
      }, 1100)
    },
    goToMenu() {
      this.transitioningToFromMenu = true;
      this.hideMailButton = true;
      setTimeout(() => {
        this.$emit('backToMenu');
      }, 10);
    }
  },
  watch: {
    hideChevrons() {
      if (this.hideChevrons) {

        setTimeout(() => {
          this.applyChevronTransition = true;
        }, 1000)

        setTimeout(() => {
          this.hideChevrons = false;
        }, 1050)
      }
    },
    photoView() {
      if (!this.photoView) {

        this.viewportDOMElement.setAttribute("content", 
        "width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no");

        setTimeout(() => {
          this.prepareMatte = false;
        }, 1000)
      }
    }
  }
}

</script>

<style scoped>

.control-container {
  display: flex; 
  opacity: 0.6; 
  color: white;
  margin-top: 4%;
  transition: 500ms;
}

.pv-background-matte {
  position: fixed; 
  width: 120vw;
  height: 120vh; 
  right: -5%; 
  top: -5%; 
  background-color: rgb(93, 93, 93); 
  transition: 750ms; 
}

img.slideshow-chevrons {
  position: fixed; 
  z-index: 2; 
  top: 1.5%; 
  max-width: 3vw;
}

img.slideshow-imgs {
  width: 90vw; 
  max-height: 85vh; 
  object-fit: cover; 
  /* object-position: top; */
  border-radius: 15px;
  color: white;
  position: relative;
  left: -50%;
  
}

.photo-out-enter-from {
  transform: translateX(-100vw)
}

.photo-out-enter-to {
  transform: translateX(0)
}

.photo-out-enter-active {
  transition: all 1s;
}

.photo-out-leave-from {
  transform: translateX(0)
}

.photo-out-leave-to {
  transform: translateX(100vw)
}

.photo-out-leave-active {
  transition: all 1s;
}

.photo-in-enter-from {
  transform: translateX(100vw);
}

.photo-in-enter-to {
  transform: translateX(0);
}

.photo-in-enter-active {
  transition: all 1s;
}

.photo-in-leave-from {
  transform: translateX(0)
}

.photo-in-leave-to {
  transform: translateX(-100vw)
}

.photo-in-leave-active {
  transition: all 1s;
}

.sculpture-img {
  width: 90vw;
  height: 90vw;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  margin-top: 8%;
}

.content-container {

  background-color: white;
  width: 90vw;
  border-radius: 0px 0px 15px 15px;
}

p, h2, h1 {
  margin: 1% 0% 1% 2.5%;
}

p.last {
  margin-bottom: 1%;
}

p {
  font-size: 10.5pt;
}

h1 {
  font-size: 16pt;
}
</style>