<template>
  <div :class="`center ${ sculptureList.length === 0 ?  '' : 'background-main' }`">

    <div :style="`position: ${ fixScrollView ?  'fixed' : 'static' }`">

      <!-- Loading Screen -->
      <div v-if="sculptureList.length === 0" class="center">
        <img style="width: 20%; margin-top: 40vh;" src="./assets/loader.gif" alt="loading...">
        <!-- <i style="color: white; opacity: 0.5;">prerelease v1.0</i> -->
      </div>

      <header class="center">
        <img style="width: 90%; height: 100%;" src="./assets/JVALogoSVG.svg" alt="hi">
      </header>

      <br><br><br>

      <!-- Sculpture Display -->
      <div v-for="sculpture in sculptureList" :key="sculpture.id">
        <div @click="selection(sculpture)" class="parent-container card show">
          <img class="img-fit" :src="sculpture.imgSrc" :alt="`Unable To Load ${sculpture.title ? `${sculpture.title} ` : '' } Image`">
          <div class="title-card">
            <div class="title-text">
              <h1 class="sculp-title">{{ sculpture.title }}</h1>
              <!-- <p class="sculp-sub-text">{{ sculpture.year }}</p> -->
            </div>
            <img class="chevron" src="./assets/main-chevron.png" alt="chevron-right">
          </div>
        </div>
      </div>

      <a class="email" href="mailto:office@julianvossandreae.com?subject=Web Inquiry">
        <footer class="center">
          <p style="color: white; font-size: 14pt;">Email Inquiry</p>
        </footer>
      </a>

    </div>

    <!-- Content View -->
    <div v-if="renderBackgroundMatte" :style="`transition: 500ms; z-index: 2; position: fixed; top: 0%; width: 100vw; height: 100vh; background-color: rgb(93, 93, 93); opacity: ${ backgroundMatte ? 1 : 0 }`"></div>
    <transition name="slide">
      <div style="z-index: 3; position: fixed; top: 0;" v-if="!menuView">
        <SculptureView ref="sculptureView" @backToMenu="exitToMenu()" :sculpture="sculpture" />
      </div>
    </transition>
    
    <br><br>
    
  </div>
</template>

<script>

import SculptureView from './components/SculptureView.vue'
import Sculpture from './SculptureClass.js'

export default {
  data: () => {
    return {
      sculptureList: [],
      menuView: true,
      sculpture: {},
      touchstartX: 0,
      fixScrollView: false,
      backgroundMatte: false,
      renderBackgroundMatte: false
    }
  },
  components: {
    SculptureView
  },
  async created() {

    // rows that will be queried with google sheets api
    const queriedRows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let tempData = [];

    for (let i = 0; i < queriedRows.length; i++) {

      tempData.push([]);
      await fetch(`https://sheets.googleapis.com/v4/spreadsheets/1hJn2ufeFvEtAD-jXAmZJRdswR-Nj0Exy3MWtmQicFaY/values/${queriedRows[i]}2:${queriedRows[i]}10000/?key=AIzaSyDJtifzdi9ZJI1zsyZYCeTHXEXJlgHnLl8`)
        .then(response => response.json())
        .then(data => {
          for (let j = 0; j < data.values.length; j++) {
            const entry = data.values[j][0] || undefined;
            tempData[i].push(entry);
          }
        })
    }

    // additional image array on column 10
    tempData[10] = [];
    await fetch(`https://sheets.googleapis.com/v4/spreadsheets/1hJn2ufeFvEtAD-jXAmZJRdswR-Nj0Exy3MWtmQicFaY/values/K2:K10000/?key=AIzaSyDJtifzdi9ZJI1zsyZYCeTHXEXJlgHnLl8`)
      .then(response => response.json())
      .then(data => {
        if (data.values) {
          for (let i = 0; i < data.values.length; i++) {
            let addedImages = data.values[i][0] || undefined;
            if (addedImages) addedImages = addedImages.split(',');
            tempData[10].push(addedImages);
          }
        }
    })

    for (let i = 0; i < tempData[0].length; i++) {

      this.sculptureList.push(new Sculpture(
        tempData[0][i],
        tempData[1][i],
        tempData[2][i],
        tempData[3][i],
        tempData[4][i],
        tempData[5][i],
        tempData[6][i],
        tempData[7][i],
        tempData[8][i],
        tempData[9][i],
        tempData[10][i]));
    }
    
    this.$nextTick(() => {
      const cards = document.querySelectorAll(".card");
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle("show", entry.isIntersecting);
          if (entry.isIntersecting) observer.unobserve(entry.target);
        });
      }, {
        threshold: 0.2
      });
      cards.forEach(card => {
        observer.observe(card);
      });
    });
  },
  methods: {
    selection(sculpture = {}) {
      
      this.menuView = false;
      this.sculpture = sculpture;
      this.lastMenuPos = window.scrollY;

      setTimeout(() => {
        this.fixScrollView = true;
      }, 500) // match component transition duration
    },
    exitToMenu() {
      this.menuView = true;
      this.fixScrollView = false;
      setTimeout(() => {
        window.scrollTo(0, this.lastMenuPos);
      }, 25)
    }
  },
  watch: {
    menuView() {
      if (!this.menuView) {
        this.renderBackgroundMatte = true;
        setTimeout(() => {
          this.backgroundMatte = true;
        }, 10)
      } else {
        this.backgroundMatte = false;
        setTimeout(() => {
          this.renderBackgroundMatte = false;
        }, 525) // matte transition duration plus 25ms
      }
    }
  }
}
</script>

<style>

a.email, a:hover.email, a:focus.email, a:active.email {
  text-decoration: none;
  color: inherit;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 7vh;
  background-color: rgb(93, 93, 93);
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 7vh;
  background-color: rgb(93, 93, 93);
}

.background-main {
  background-color: rgb(93, 93, 93);
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(100vw)
}

.slide-leave-from, .slide-enter-to {
  transform: translateX(0vw)
}

.slide-leave-active, .slide-enter-active {
  transition: all 500ms;
}

.card {
  opacity: 0;
  transition: 400ms;
}

.card.show {
  opacity: 1;
}

body {
  margin: 0px;
  background-color: rgb(93, 93, 93);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.sculp-title {
  font-size: 15pt;
  margin: 0% 0% 0% 4%;

}
.sculp-sub-text {
  font-size: 12pt;
  margin: 0% 0% 0% 4%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img-fit {
  width: 85vw;
  height: 85vw;
  object-fit: cover;
  border-radius: 15px;
  position: absolute;
}
.title-card {
  width: 85vw;
  height: 15vw;
  border-radius: 0px 0px 15px 15px;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title-text {
  flex-basis: 90%;
}
.parent-container {
  width: 85vw;
  height: 85vw;
  margin-bottom: 7.5%;
  position: relative;
}
.chevron {
  width: 10vw;
  height: 10vw;
  flex-basis: 10%;
}
</style>
