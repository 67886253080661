export default class Sculpture {

  constructor(title, year, medium, dimensions, weight, location, explanation, linkName, link, imgSrc, addedImgs) {
    this.location = location;
    this.title = title;
    this.year = year;
    this.link = link;
    this.linkName = linkName
    this.medium = medium;
    this.dimensions = dimensions;
    this.weight = weight;
    this.explanation = explanation;
    this.imgSrc = imgSrc;
    this.addedImgs = addedImgs;
  }
}